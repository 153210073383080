import axios from "axios";
import { auth } from "./Firebase";


const URL = "http://192.168.67.131:8081/";
const tokenURL = "http://34.31.137.130:80/";
const serverURL = "https://aryoconnect.in/";
let RAZOR_PAY_IFSC = "https://ifsc.razorpay.com/"
const CMS_URL = "https://aryo-api.connecttocms.in/"

export const setUid = async (mobile, callBack) => {
  // console.log("inside setUid")
  const result = await axios.post(URL + "generateUid", {
    mobile: mobile,
  });
  // console.log(result.data);
  callBack(result.data);
};

export const verifyPan = async (pan, callBack) => {
  // console.log("varifyPan ", pan);
  const result = await axios.post(serverURL + "verifyPan", {
      uid: auth.currentUser.uid,
      pan: pan,
  });
  // console.log("result ", result.data)
  callBack(result.data);
  // callBack(
  //   {status: 'SUCCESS', name: 'NIKHIL WALIA'});
};

export async function loginWithToken(mobile, callBack) {
  const result = await axios.get(tokenURL + "getCustomToken", {
    params: {
      mobile: mobile,
    },
  });
  callBack(result.data);
}

export const getAgentByUid = async (uid, callBack) => {
  // console.log(uid);
  const result = await axios.get(URL + "getAgentByUid", {
    params: { uid: uid },
  });
  callBack(result.data);
};

// export const getDocuments =async (callBack) => {
//   console.log("reading documents")
//   const result = await axios.get(URL +'getDocuments');
//   console.log(result);
// }

// uploading BasicDetails
export const submitBasicDetails = async (details, callBack) => {
  const result = await axios.post(URL + "addNewDSA", { details: details });

  callBack(result.data);
};

// updateBasicDetails

export const updateBasicDetails = async (details, callBack) => {
  const result = await axios.put(URL + "updateBasicDetails", { details: details });

  callBack(result.data);
};

// update KYC

export const updateKycDetails = async (details, callBack) => {
  const result = await axios.put(URL + "updateKycDetails", { details: details });

  callBack(result.data);
};

// updateBankDetails
export const updateBankDetails = async (details, callBack) => {
  const result = await axios.put(URL + "updateBankDetails", { details: details });

  callBack(result.data);
};


// updateCompanyDetails

export const updateCompanyDetails = async (details ,  callBack) => {
  const result = await axios.put(URL + 'updateCompanyDetails' , {details : details});
  // console.log(result);
  callBack(result.data);
}

// UploadingImages
export const uploadFile = async (documentId, formData, callBack) => {
  // console.log(documentId)
  try {
    const result = await axios.post(URL + "uploadDocuments", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
      params: {
        documentId: documentId,
      },
    });

    // console.log(result.data);

    callBack(result.data);
  } catch (error) {
    console.error(error);
  }
};

export async function getBankName(ifsc, callBack) {
    
  try {
      const result = await axios.get(RAZOR_PAY_IFSC + ifsc)
      callBack(result.data)
  } catch (err) {
      console.log(err);
      callBack(null);
  }
}

//Uploading BankDetails
export const uploadBankDetails = async (documentID, details, callBack) => {
  const result = await axios.post(URL + "addBankingDetails", {
    documentID: documentID,
    details: details,
  });

  callBack(result.data);
};

// gettingBasicDetails
export const getBasicDetails = async (documentID, callBack) => {
  // const result = await axios.get(URL + "getDSADetails", {
  //   params: { documentID: documentID },
  // });

  // var arr = [];

  // console.log(result.data)
  // Object.entries(result.data).map((response) => {
  //     arr.push({
  //       "name" : response[0].name,
  //       "email" : response[0].email,
  //       "mobile" : response [0].mobile
  //     })

  // })

  // console.log(arr);

  // callBack(result.data);
};

// getDSADetailsByMobile

export const getDSADetailsByMobile = async (mobile, callBack) => {
  // console.log(mobile);
  const result = await axios.get(URL + "getDSADetailsByMobile", {
    params: { mobile: mobile },
  });

  // console.log(result.data);
  callBack(result.data);
};

export const getAgentProfile = async (uid, callBack) => {
  
  const result = await axios.get(serverURL + "getAryoAgentProfileByUid", {
    params: { uid: uid },
  });
  callBack(result.data);
};

// getKYCDetails
export const getKYCdetails = async (documentID, callBack) => {
  const result = await axios.get(URL + "getKYCDetails", {
    params: { documentID: documentID },
  });

  // console.log(result.data)
  callBack(result.data);
};

// getBankingDetails

export const getBankdetails = async (documentID, callBack) => {
  const result = await axios.get(URL + "getBankDetails", {
    params: { documentID: documentID },
  });
  callBack(result.data[0] ,result.data[1]);
};

// uploadDocumentsURL

export const uploadDocumentsURL = async (documentID, Url, callBack) => {
  const result = await axios.post(URL + "uploadDocumentURL", {
    documentID: documentID,
    Url: Url,
  });
  callBack(result.data);
};

// DSA Enquiry
export const submitEnquiry = async (enquiry , callBack) => {
  const result =  await axios.post(serverURL + 'submitDSAEnquiry' ,{
    details : enquiry
  })
  callBack(result.data);
}


export async function generateOtp(mobile, callBack) {
  const result = await axios.post(serverURL + "sendOtp", { mobile: mobile, isTeam: false });
  // console.log(result.data);
  callBack(result.data);
  // callBack({status: 'success', orderId: '1703784067285'})
}

export async function reGenerateOtp(orderId, callBack) {
  const result = await axios.post(serverURL + "resendOtp", { orderId: orderId });
  callBack(result.data);
  // callBack({status: 'success', orderId: '1703784067285'})
}

export async function getFirebaseToken(mobile, callBack) {
  // console.log(mobile)
  const result = await axios.get(serverURL + "getCustomToken/?mobile=" + mobile);
  callBack(result.data);
}

export async function verifyOtp(mobile, orderId, otp, callBack) {
  // console.log(mobile);
  const result = await axios.post(serverURL + "verifyOtp", {
      mobile: mobile,
      orderId: orderId,
      otp: otp,
  });
  callBack(result.data);
  // callBack({'status': 'success', 'isOTPVerified': true});
}

export async function checkPincode(pincode, callBack) {
  // console.log(pincode);
  const result = await axios.get(serverURL + "checkPincode", {
      params: {
          pincode: pincode,
      },
  });
  callBack(result.data);
}

export async function sendDeleteRequest(agentMobile, callBack) {
  const result = await axios.get(serverURL + 'deleteAryoAccount', {
    params : {
      mobile : agentMobile
    }
  })
  callBack(result.data);
}

export const getAryoBlogs = async (callback) => {
  const result = await axios.get(CMS_URL + "aryo/getAryoBlogs");
  callback(result.data);
};
