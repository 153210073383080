import React from 'react'
import Blogs from "./Blogs";
import BlogsHeader from './BlogsHeader';

function MainBlogPage() {
  return (
    <div className="contact-screen">
    <div className="contact_header_container">
      <BlogsHeader />
    </div>
    <Blogs />
  </div>
  )
}

export default MainBlogPage
