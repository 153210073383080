import { createContext, useEffect, useState } from "react";
import { getAryoBlogs } from "../Connection";

export const BlogsContext = createContext();

export const BlogsProvider = ({ children }) => {
  const [blogs, setBlogs] = useState([]);

  useEffect(() => {
    getAryoBlogs((res) => {
      res.reverse().splice(res.length - 1, 1);
      setBlogs(res);
    });
  }, []);

  return (
    <BlogsContext.Provider value={{ blogs }}>{children}</BlogsContext.Provider>
  );
};
