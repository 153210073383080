import React from "react";
import { Routes, Route } from "react-router-dom";
import MainHome from "./MainHome";
import Home from "./Home";
import ContactPage from "./ContactPage";
import TermsAndConditions from "./TermsAndConditions";
import Privacy from "./Privacy";
import Error from "./Error";
import Login from "./Login";
import DetailsView from "./DetailsView";
import PersonalDetails from "./personalDetails";
import KycDetails from "./KycDetails";
import BankDetails from "./BankDetails";
import DeleteAcccountPageForm from "./DeleteAcccountPageForm";
import { BlogsProvider } from "../contexts/BlogsContext";
import BlogHome from "./BlogHome";
import MainBlogPage from "./MainBlogPage";
import BlogDetails from "./BlogDetails";

function RouterPath() {
  const element = (
    <>
      <Routes>
        <Route path="/" element={<MainHome />}>
          <Route path="/" element={<Home />}></Route>
          <Route path="become-a-dsa" element={<ContactPage />} />
        </Route>
        <Route
        path="/blogs/*"
        element={
          <BlogsProvider>
            <BlogHome />
          </BlogsProvider>
        }
      >
        <Route index element={<MainBlogPage />} />
        <Route path=":category/:heading" element={<BlogDetails />} />
      </Route>
        <Route path="/privacy" element={<Privacy />} />
        <Route path="/terms" element={<TermsAndConditions />} />
        <Route path="/login" element={<Login/>} />
        <Route path="/details" element={<DetailsView/>} />
        <Route path="/details/profile" element={<PersonalDetails/>}/>
        <Route path="/details/kyc" element={<KycDetails/>}/>
        <Route path="/details/bankDetails" element={<BankDetails/>}/>
        <Route path="/deleteaccount" element={<DeleteAcccountPageForm/>}/>
        <Route path="*" element={<Error />} />
      </Routes>
    </>
  );

  return element;
}

export default RouterPath;
