import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

function BlogsShimmer() {

  return (
    <>
      <div className="blogs-wrapper">
        <div className="blogs-container">

          <div className="first-box">
            <img src="" alt="" />
            <div className="description-box">
              <div className="description-box-heading">
              <Skeleton height={300} />
                <p></p>
                {/* <h3></h3> */}
              </div>
              <div className="description-box-date">
                <p></p>
              </div>
            </div>
          </div>
          <div className="first-box">
            <img src="" alt="" />
            <div className="description-box">
              <div className="description-box-heading">
              <Skeleton height={300} />
                <p></p>
                {/* <h3></h3> */}
              </div>
              <div className="description-box-date">
                <p></p>
              </div>
            </div>
          </div>
          <div className="first-box">
            <img src="" alt="" />
            <div className="description-box">
              <div className="description-box-heading">
              <Skeleton height={300} />
                <p></p>
                {/* <h3></h3> */}
              </div>
              <div className="description-box-date">
                <p></p>
              </div>
            </div>
          </div>
          <div className="first-box">
            <img src="" alt="" />
            <div className="description-box">
              <div className="description-box-heading">
              <Skeleton height={300} />
                <p></p>
                {/* <h3></h3> */}
              </div>
              <div className="description-box-date">
                <p></p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default BlogsShimmer;
