import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { BrowserRouter} from "react-router-dom";
import reportWebVitals from "./reportWebVitals";
import { Constants } from "./Components/Constant";

const root = ReactDOM.createRoot(document.getElementById("root"));
console.log("subdomain ", window.location.hostname.split('.')[0], ' platform ', 
  window.navigator.userAgent);
if (window.location.hostname.split('.')[0] === 'app') {
  let win = '';
      if (window.location.pathname.split("/")[1] === "refAr") {
        win = window.open(
           Constants.PLAY_STORE +
          "&referrer=utm_medium%3Dreferral%26invitedBy%3D" +
          window.location.pathname.split("/")[2],
          "_self"
        );
      } else {
        win = window.open('https://aryo.co.in', '_self');
      }

      if (win !== null) win.focus();
      
} else {
  root.render(
    <React.StrictMode>
      <BrowserRouter>
      <App />
      </BrowserRouter>
    </React.StrictMode>
    
  );
}


reportWebVitals();
