import React, { useState} from "react";
import { Link , useLocation } from "react-router-dom";
import aryologo from "../images/aryologowhite.png";
import "../css/header.css";

function BlogsHeader() {
  const [isActive, setIsActive] = useState(false);
  const location = useLocation();

  function openNav() {
    document.getElementById("myNav").style.width = "50%";
    document.body.style.overflow = "hidden";
    if (isActive) {
      closeNav();
    }
    setIsActive(!isActive);
  }

  function closeNav() {
    document.getElementById("myNav").style.width = "0%";
    document.body.style.overflow = "auto";
    setIsActive(false);
  }

  function goBottom() {
    closeNav();
    window.scrollTo({
      top: document.body.scrollHeight,
      behavior: "smooth",
    });
  }

  return (
    <div className={`header-wrapper ${isActive ? "active" : ""}`}>
      <div className="company_info">
        <div>
          <Link to="/" className="linkPath-logo">
            <img src={aryologo} alt="" />
          </Link>
        </div>

        <div>
          <span
            id="hamburger-menu"
            className={isActive ? "active" : ""}
            onClick={() => {
              openNav();
            }}
          >
            <p></p>
            <p></p>
            <p></p>
          </span>
        </div>
      </div>
      <div className="navbar-menu">
        <ul className="menu-1">
          <li>
            <Link to="/" className="linkPath-menu" onClick={closeNav}>
              Home
            </Link>
          </li>
          <li>
            <a href="/blogs" className="linkPath-menu" onClick={closeNav} target="_blank">
              Blogs
            </a>
          </li>

          <li>
            <div className="linkPath-menu" onClick={goBottom}>
              Contact Us
            </div>
          </li>
        </ul>
      </div>
    </div>
  );
}

export default BlogsHeader;
