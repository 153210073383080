import React from "react";
import Footer from "./Footer";
import { Outlet } from "react-router-dom";
import BlogsNavbar from "./BlogsNavbar";

function BlogHome() {
  return (
    <>
      <BlogsNavbar />
      <Outlet />
      <Footer />
    </>
  );
}

export default BlogHome;
