import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import badge from "../images/googleplay_badge.png";
import aryologo from "../images/aryologoblue.png";
import "../css/navbar.css";

function BlogsNavbar() {
  const [isActive, setIsActive] = useState(false);
  const [activeLink, setActiveLink] = useState("");
  const [isBadgeVisible, setIsBadgeVisible] = useState(false);
  const location = useLocation();

  useEffect(() => {
    if (location.pathname.includes("become-a-dsa")) {
      setActiveLink("becomeADSA");
    } else if (location.pathname.includes("blogs")) {
      setActiveLink("blogs");
    } else if (location.pathname.includes("")) {
      setActiveLink("home");
    } else {
      setActiveLink("none");
    }
  }, [location.pathname]);

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition =
        window.scrollY || document.documentElement.scrollTop;

      if (scrollPosition > 100) {
        setIsBadgeVisible(true);
      } else {
        setIsBadgeVisible(false);
      }

      const navbar = document.getElementsByClassName("navbar-wrapper")[0];
      if (window.innerWidth > 768) {
        if (scrollPosition > 20) {
          navbar.style.top = "0";
        } else {
          navbar.style.top = "-50px";
        }
      }
    };

    window.onscroll = handleScroll;

    return () => {
      window.onscroll = null;
    };
  }, []);

  function openNav() {
    document.getElementById("myNav").style.width = "50%";
    document.body.style.overflow = "hidden";
    if (isActive) {
      closeNav();
    }
    setIsActive(!isActive);
  }

  function closeNav() {
    document.getElementById("myNav").style.width = "0%";
    document.body.style.overflow = "auto";
    setIsActive(false);
  }

  function goToTop() {
    closeNav();
    window.scrollTo({
      top: 0,
      // behavior: "smooth",
    });
  }

  function goBottom() {
    window.scrollTo({
      top: document.body.scrollHeight,
      behavior: "smooth",
    });
    closeNav();
  }

  return (
    <div className={`navbar-wrapper ${isActive ? "active" : ""}`}>
      <div className="company_info">
        <div>
          <Link
            to="/"
            className="linkPath-logo_navbar"
            onClick={() => {
              goToTop();
              setActiveLink("home");
            }}
          >
            <img src={aryologo} alt="aryo" />
          </Link>
        </div>

        <div className="hamburger">
          <Link
            to="https://play.google.com/store/apps/details?id=com.aryo.aryoleads&referrer=utm_source%3Daryo-website%26utm_term%3Dwebsite%26utm_campaign%3Dwebsite"
            className={`badge_box ${isBadgeVisible ? "visible" : ""}`}
          >
            <img src={badge} alt="" id="navbar_badge" />
          </Link>

          <div>
            <span
              id="hamburger-menu_navbar"
              className={isActive ? "active" : ""}
              onClick={() => {
                openNav();
              }}
            >
              <p></p>
              <p></p>
              <p></p>
            </span>
          </div>
        </div>
      </div>
      <div className="navbar-menu">
        <ul className="menu-1">
          <li>
            <Link to="/" className="linkPath-menu_navbar" onClick={goToTop}>
              Home
            </Link>
          </li>
          <li>
            <a
              href="/blogs"
              className="linkPath-menu_navbar"
              onClick={goToTop}
              target="_blank"
            >
              Blogs
            </a>
          </li>

          <li>
            <div className="linkPath-menu_navbar" onClick={goBottom}>
              Contact Us
            </div>
          </li>
        </ul>
      </div>

      <div id="myNav" className={`hamburger-link ${isActive ? "active" : ""}`}>
        <div className="hamburger-content">
          <ul className="menu-2">
            <li id="logo_navbar">
              <Link
                to="/"
                onClick={() => {
                  goToTop();
                  setActiveLink("home");
                }}
              >
                <img src={aryologo} alt="" id="navbar_badge_aryo" />
              </Link>
            </li>
            <li>
              <Link
                to="/"
                className={`linkPath-menu_navbar ${
                  activeLink === "home" ? "active-link" : ""
                }`}
                onClick={() => {
                  goToTop();
                  setActiveLink("home");
                }}
              >
                Home
              </Link>
            </li>
            <li>
              <a
                href="/blogs"
                className={`linkPath-menu_navbar ${
                  activeLink === "blogs" ? "active-link" : ""
                }`}
                onClick={() => {
                  goToTop();
                  setActiveLink("blogs");
                }}
                target="_blank"
              >
                Blogs
              </a>
            </li>

            <li>
              <div className="linkPath-menu_navbar" onClick={goBottom}>
                Contact Us
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}

export default BlogsNavbar;
