import React from "react";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { useMediaQuery } from "react-responsive";

function DetailsShimmer() {
  // Media query to check if max-width is 768px
  const isMobile = useMediaQuery({ query: "(max-width: 768px)" });

  return (
    <>
      <div className="blog-details-wrapper">
        <div className="blog-details-box">
          <div className="first-block">
            <h1>
              <Skeleton height={50} width={isMobile ? 310 : 700} />
            </h1>
            <p id="blogDate">
              <Skeleton height={10} width={isMobile ? 200 : 300} />
            </p>
            <Skeleton height={300} width={isMobile ? 310 : 700} />

            <div id="blog-description">
              <Skeleton height={10} width={isMobile ? 200 : 300} />
            </div>
            <br />

            <div>
              <div id="blog-subheading" />
              <br />
              <div className="details-description">
                <div>
                  <div id="blog-info" />
                  <br />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default DetailsShimmer;
